export const splitParams = (params) => {
    return Object.entries(params).map(item => `${item[0]}=${item[1]}`).join('&')
}


export const spliceList = (arr, num = 6) => {
    let result = []
    for (let i = 0, len = arr.length; i < len; i += num) {
        result.push(arr.slice(i, i + num));
    }
    return result
}


export const isWeixin = () => {
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}
