import React from "react";
import {WithRouter} from '../../conpoment/WithRouter'
import ShareModal from '../../conpoment/ShareModal'
import {getCodeAdmin, shopRrgister} from '../../service/http'
import md5 from 'js-md5';
import './style.scss'
import {Mask, Toast} from "antd-mobile";
import moment from "moment";
import {sqgjx_app_link} from "../../config/serviceConfig";

class ShopRegister extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showMask: false,
            isAlreadyAsked: false,
            remainSecond: 0
        }
        this.timer = ''
    }


    showModal = () => {
        let time = 0
        try {
            time = Number(window.location.href.split('?')[1])
        }catch (e) {
            console.log(e)
        }
        if(time && time > 0 && moment().valueOf() > time) {
            this.setState({showMask: true})
            Toast.show({
                icon: 'fail',
                content: '二维码已过期',
                duration: 0
            })
            return
        }
        const titleImg = require('../../assets/image/xzt/bind_ios_title1.png')
        this.shareModalRef.show(titleImg)
    }


    _onOk = async (param) => {
        document.activeElement.blur();
        let u = window.navigator.userAgent;
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
        let hrefParams = window.location.href.split('?');
        let code = '';
        if (hrefParams.length == 2) {
            let splitParams = hrefParams[1].split('&');
            if (splitParams.length > 0) {
                code = splitParams[0].split('=')[1];
            }
        }
        const params = {
            adminname: param.phone,
            password: '',
            yzCode: param.password,
            code,
            sign: md5(param.phone),
            resource: isAndroid ? 'android' : 'ios',
            systemVersion: "",
            phoneType: "",
            appVersion: "",
            channelNo: "",
        };
        try {
            const result = await shopRrgister(params);
            const { message: msg, code} = result;
            if (code === '0') {
                Toast.show({
                    icon: 'success',
                    content: '注册成功',
                })
                this.downloadPackage();
            } else {
                // this.setState({showMask: true})
                Toast.show({
                    icon: 'fail',
                    content: msg,
                    // duration: 0
                })
                if(msg === '该账号已注册') {
                    this.downloadPackage()
                }
            }
        } catch (error) {

        }
    };
    get onOk() {
        return this._onOk;
    }

    set onOk(value) {
        this._onOk = value;
    }

    downloadPackage = () => {
        let u = window.navigator.userAgent
        // let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

        if (isIOS) {
            if(sqgjx_app_link.ios){
                window.location.href = sqgjx_app_link.ios;
            }else {
                Toast.show({
                    icon: 'fail',
                    content: '暂无ios下载',
                })
            }
        }else {
            window.location.href = sqgjx_app_link.android;
        }
    }

    getCode = async (phone) => {
        let _phone = phone
        try {
            _phone = phone.replace(/\s/g, '');
        }catch (e) {

        }
        if (!_phone) {
            Toast.show({
                icon: 'fail',
                content: '请输入手机号',
            })
            return
        }
        if(isNaN(_phone)) {
            Toast.show({
                icon: 'fail',
                content: '请输入正确手机号',
            })
            return
        }
        const params = {
            phone: _phone
        }
        const result = await getCodeAdmin(params);
        if (result.code === '0') {
            Toast.show({
                icon: 'success',
                content: '发送成功',
            })
            this.setState({
                isAlreadyAsked: true,
                remainSecond: 60,
            });
            // 开始倒计时
            this.beginTimer();
        }
    }

    beginTimer = () => {
        this.clearTimer();
        this.timer = setInterval(() => {
            this.setState({remainSecond: this.state.remainSecond - 1});
            if (this.state.remainSecond <= 0) {
                this.setState({isAlreadyAsked: false, remainSecond: 0});
                // 清除计时器
                this.clearTimer();
            }
        }, 1000)
    }

    clearTimer = () => {
        this.timer && clearInterval(this.timer);
    }

    render() {
        return (
            <div className="shop_register_view">
                <div className="title_view">
                    <img src={require('../../assets/image/xzt/shop_tools.png')} alt={''}/>
                </div>
                <div className="note_view">
                    <img className="left_right_img" src={require('../../assets/image/xzt/left@3x.png')} alt={''}/>
                    <span className="title_center">销量销量涨不停</span>
                    <img className="left_right_img" src={require('../../assets/image/xzt/right@3x.png')} alt={''}/>
                </div>
                <div className="bottom_btn" onClick={this.showModal}>
                    <span>我是店主，我要入驻</span>
                </div>
                <ShareModal
                    ref={ref => this.shareModalRef = ref}
                    codeText={!this.state.isAlreadyAsked ? '获取验证码' : '剩余' + this.state.remainSecond + 's'}
                    onOk={this.onOk}
                    getCode={this.getCode}/>
                <Mask visible={this.state.showMask}>

                </Mask>
            </div>
        );
    }
}


export default WithRouter(ShopRegister)

