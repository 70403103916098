import React from "react";
import {WithRouter} from '../../conpoment/WithRouter'
import queryString from 'query-string';
import {Toast} from "antd-mobile";
import {isWeixin} from "../../utils/utils";
import moment from "moment/moment";
import {assistant_app_link, gjzx_app_link, sqgjx_app_link, ticket_app_link} from "../../config/serviceConfig";
import {QRCode, Button} from "antd";

class DownloadApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            app_list: [
                {name: '红牛工具箱', icon: require('../../assets/ic_launcher1.png'), link: gjzx_app_link},
                {name: '与店同行', icon: require('../../assets/ic_launcher2.png'), link: sqgjx_app_link},
                {name: '出票员', icon: require('../../assets/ic_launcher3.png'), link: ticket_app_link},
                {name: '收款助手', icon: require('../../assets/ic_launcher4.png'), link: assistant_app_link},
            ],
            select_app: {},
            isWxBrowser: isWeixin()
        }
    }

    componentDidMount() {
        const {app_list} = this.state
        let search = {}
        try {
            search = window.location.href.split('?')[1]
        }catch (e) {

        }
        const params = queryString.parse(search);
        if(!params.time) {
            return;
        }
        if( params.time > 0 && moment().valueOf() > params.time) {
            Toast.show({
                icon: 'fail',
                content: '二维码已过期',
                duration: 0,
            })
            return
        }
        try {
            this.setState({select_app: app_list[params.type] || {}})
        }catch (e) {

        }
    }

    handleBtn = () => {
        const {select_app} = this.state
        let u = window.navigator.userAgent
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
            if(select_app.link.ios){
                window.location.href = select_app.link.ios;
            }else {
                Toast.show({
                    icon: 'fail',
                    content: '暂无ios下载',
                })
            }
        }else {
            window.location.href = select_app.link.android;
        }
    }

    render() {
        const {select_app, isWxBrowser} = this.state
        let u = window.navigator.userAgent
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (!isIOS && isWxBrowser) {
            return (
                <div className="share_modal_view">
                    <img className="wx_open_img" src={require('../../assets/image/xzt/browser_open.png')} alt={''}/>
                </div>
            )
        }
        return (
            <div>
                {select_app.icon && <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <img style={{width: 100 , height: 100, marginTop: 100, borderRadius: 10}} alt={''} src={select_app.icon}/>
                    <div style={{color: '#000', fontSize: 20, fontWeight: '500', marginTop: 15}}>{select_app.name}</div>
                    <Button
                        size={'large'}
                        style={{fontSize: 16, fontWeight: '500', width: 120 ,marginTop: 100}}
                        onClick={() => this.handleBtn()}>
                        {'下载'}
                    </Button>
                </div>}
                {!select_app.icon && <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: 120}}>
                    <QRCode value={'-'} status="expired"/>
                    <div style={{color: '#DB4444', fontSize: 20, fontWeight: '500', marginTop: 20}}>{'二维码已过期'}</div>
                </div>}
            </div>
        );
    }
}


export default WithRouter(DownloadApp)
