import {Toast} from "antd-mobile";
import React from "react";
import {isWeixin} from '../../utils/utils'
import './style.scss'


class ShareModal extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            phone: '',
            password: '',
            isShow: false,
            titleImg: '',
            isWxBrowser: isWeixin()
        }
    }

    ok = () => {
        const {phone, password} = this.state
        let _phone = phone
        try {
            _phone = phone.replace(/\s/g, '');
        }catch (e) {

        }
        if (!_phone) {
            Toast.show({
                icon: 'fail',
                content: '请输入手机号',
            })
            return
        }
        if(isNaN(_phone)) {
            Toast.show({
                icon: 'fail',
                content: '请输入正确手机号',
            })
            return
        }
        if(_phone.length !== 11) {
            Toast.show({
                icon: 'fail',
                content: '请输入11位手机号',
            })
            return
        }

        if (!password) {
            Toast.show({
                icon: 'fail',
                content: '请输入密码',
            })
            return
        }

        const params = {
            phone: _phone,
            password
        }
        this.props.onOk(params)
        this.hide()
    }


    inputChange = (key, value) => {
        const param = {}
        param[key] = value
        if (key === 'phone' && value.length <= 11) {
            this.setState(param)
        }
        if (key === 'password' && value.length <= 14) {
            this.setState(param)
        }
    }


    show = (img) => {
        this.setState({isShow: true, titleImg: img})
    }


    hide = (e) => {
        try {
            if (['IMG', 'INPUT', 'BUTTON'].some(v => v === e.target.tagName) || e.target.className === 'content') {
                return
            }
        } catch (e) {

        }
        this.setState({
            phone: '',
            password: '',
            isShow: ''
        })
    }


    render() {
        const {phone, password, isShow, titleImg, isWxBrowser} = this.state
        if (isShow) {
            if (isWxBrowser) {
                return (
                    <div className="share_modal_view">
                        <img className="wx_open_img" src={require('../../assets/image/xzt/browser_open.png')}/>
                    </div>
                )
            } else {
                return (
                    <div className="share_modal_view" onClick={this.hide}>
                        <div className="verify">
                            <div className="title">
                                <img src={titleImg} alt=''/>
                            </div>
                            <div className="content">
                                <div className="input_view">
                                    <input placeholder="请输入手机号" value={phone}
                                           onChange={(e) => this.inputChange('phone', e.target.value)}/>
                                </div>
                                <div className="input_view">
                                    <input placeholder="请输入验证码" value={password}
                                           style={{width: '9.5rem'}}
                                           onChange={(e) => this.inputChange('password', e.target.value)}/>
                                    <div className="yzmBtn" onClick={(e) => {
                                        e.stopPropagation()
                                        this.props.getCode(phone)
                                    }}>{this.props.codeText}</div>
                                </div>
                                <button className="sureBtn" onClick={this.ok}>立即注册</button>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <></>
            )
        }
    }
}


export default ShareModal
