import React from "react";
import './style.scss'


const Header = () => {

    return (
        <div className="red_envelope_header_view">
            <div className="content">
                <p className="p1">天降红包速来领</p>
            </div>
        </div>
    )
}


export default Header