import React from "react";
import {WithRouter} from '../../conpoment/WithRouter'
import md5 from 'js-md5'
import ShareModal from '../../conpoment/ShareModal'
import {registAndCode, sendUserCode} from '../../service/http'
import './style.scss'
import {Mask, Toast} from "antd-mobile";
import {gjzx_app_link} from "../../config/serviceConfig";


class UserRegister extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showMask: false
        }
    }

    showModal = () => {
        const titleImg = require('../../assets/image/xzt/bind_ios_title.png')
        this.userRegisterRef.show(titleImg)
    }


    onOk = async (param) => {
        document.activeElement.blur();
        let searchStr = window.location.href || '';
        let queryObj = {};
        let queryArr = searchStr.replace('?', '').split('&');
        queryArr.forEach(function (item) {
            queryObj[item.split('=')[0]] = item.split('=')[1];
        });
        let userIdKey = window.location.href.split('?')
        // eslint-disable-next-line no-mixed-operators
        // let userId = queryObj[userIdKey[0] + 'id'] && queryObj[userIdKey[0] + 'id'].replace(/\D/ig, '') || '';
        let userId = queryObj[userIdKey[0] + 'id'] && queryObj[userIdKey[0] + 'id'] || '';
        let inviteId = queryObj.inviteId || '';
        let u = window.navigator.userAgent
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
        const params = {
            username: param.phone,
            password: '',
            // storeId: userId === '' ? userId : "ds" + userId,
            storeId: userId === '' ? userId :  userId,
            inviteId: inviteId,
            channelNo: isAndroid ? 'android' : 'ios',
            sign: md5(param.phone + param.password),
            yzCode: param.password,
        }
        try {
            // const result = await userRegister(params)
            const result = await registAndCode(params)
            const {code, message: msg} = result
            if (code === '0') {
                Toast.show({
                    icon: 'success',
                    content: '注册成功',
                })
                this.downloadPackage()
            } else {
                if(msg === '平台暂停注册！') {
                    this.setState({showMask: true})
                    Toast.show({
                        icon: 'fail',
                        content: msg,
                        duration: 0
                    })
                    return
                }
                Toast.show({
                    icon: 'fail',
                    content: msg,
                })

            }
        } catch (error) {

        }
    }


    downloadPackage = () => {
        var u = window.navigator.userAgent
        var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端;
        if (isIOS) {
            if(gjzx_app_link.ios){
                window.location.href = gjzx_app_link.ios;
            }else {
                Toast.show({
                    icon: 'fail',
                    content: '暂无ios下载',
                })
            }
        }else {
            window.location.href = gjzx_app_link.android;
        }
    }

    getCode = async (phone) => {
        let _phone = phone
        try {
            _phone = phone.replace(/\s/g, '');
        }catch (e) {

        }
        if (!_phone) {
            Toast.show({
                icon: 'fail',
                content: '请输入手机号',
            })
            return
        }
        if(isNaN(_phone)) {
            Toast.show({
                icon: 'fail',
                content: '请输入正确手机号',
            })
            return
        }
        const params = {
            username: _phone
        }
        const result = await sendUserCode(params);
        if (result.code === '0') {
            Toast.show({
                icon: 'success',
                content: '发送成功',
            })
            this.setState({
                isAlreadyAsked: true,
                remainSecond: 60,
            });
            // 开始倒计时
            this.beginTimer();
        }else {
            Toast.show({
                icon: 'fail',
                content: result.message,
            })
        }
    }

    beginTimer = () => {
        this.clearTimer();
        this.timer = setInterval(() => {
            this.setState({remainSecond: this.state.remainSecond - 1});
            if (this.state.remainSecond <= 0) {
                this.setState({isAlreadyAsked: false, remainSecond: 0});
                // 清除计时器
                this.clearTimer();
            }
        }, 1000)
    }

    clearTimer = () => {
        this.timer && clearInterval(this.timer);
    }


    render() {
        return (
            <div className="user_register_view">
                <div>
                    <img className="title_img" src={require('../../assets/image/xzt/content@2x.png')} alt={''}/>
                </div>
                <div className={'UserRegister_div1'}>
                    点击下方注册按钮  即可注册成功
                </div>
                <center className={'bottom_btn1'}>
                    <div className="bottom_btn" onClick={this.showModal}>
                        <span className="bottom_text">下载</span>
                    </div>
                </center>
                <ShareModal
                    ref={ref => this.userRegisterRef = ref}
                    codeText={!this.state.isAlreadyAsked ? '获取验证码' : '剩余' + this.state.remainSecond + 's'}
                    onOk={this.onOk}
                    getCode={this.getCode}/>
                <Mask visible={this.state.showMask}>

                </Mask>
            </div>
        )
    }
}


export default WithRouter(UserRegister)
