import React from "react";
import {WithRouter} from '../../conpoment/WithRouter'
import Header from './Header/Header.jsx'
import Body from "./Body/index.jsx";


class RedEnvelope extends React.Component {

    componentDidMount() {

    }


    render() {
        return (
            <div>
                <Header/>
                <Body {...this.props}/>
            </div>
        );
    }
}


export default WithRouter(RedEnvelope)
