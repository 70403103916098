import React from "react";
import './style.scss'
import PicAuthCode from "../../../conpoment/PicAutoCode";
import { login, drawRedPacket,register } from '../../../service/http'
import {Input, Toast} from "antd-mobile";



class Body extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            isRegster:false,
            phone:'',
            password:'',
            code:''
        }
        this.token = ''
    }


    componentDidMount(){
        this.getUrlQuery()
    }





    getUrlQuery = () => {
        const splitUrls = window.location.href.split('?')
        if(splitUrls.length >=2 ){
            const querys = splitUrls[1].split(':')
            if(querys.length>=3){
                let storeIds = querys[1].indexOf('%')!== -1 ?  querys[1].split('%') : querys[1].split('1')
                return {
                    storeId:storeIds.length >=2 ? storeIds[0] : '',
                    rid:querys[2],
                    token:this.token ,
                    systemVersion: "",
                    phoneType: "",
                    channelNo: "",
                    appVersion: "",
                    resource: "h5",
                    clientType: "",
                }
            }
        }
    }


    toRegister = () => {
        this.setState({isRegster:!this.state.isRegster})
    }


    login = async () => {
        const { phone,password } = this.state
        const params = {
            adminId:"",
            storeId:this.getUrlQuery().storeId,
            username:phone,
            password,
            systemVersion: "",
            phoneType: "",
            channelNo: "",
            appVersion: "",
            resource: "h5",
            clientType: "",
        }
       try {
          const result =  await login(params)
          const { data, code, message : msg } = result
          if(code === '0'){
              const { token } = data
              this.token = token
            //   alert(data.token)
              this.getRedEnvelope()
          }else{
              Toast.show({
                  icon: 'fail',
                  content: msg,
              })
          }
       } catch (error) {

       }
    }


    register = async () => {
        const { phone,password } = this.state
        const params = {
            sign:"",
            storeId:this.getUrlQuery().storeId,
            username:phone,
            password:password,
            inviteId: "",
            appPackage:"CloudSurvey",
            resource:"",
            systemVersion:"",
            phoneType:"",
            appVersion:"",
            channelNo:"",
        }
        try {
            const result =  await register(params)
            const { data, code, message : msg } = result
            if(code === '0'){
                const { token } = data
                this.token = token
                this.getRedEnvelope()
            }else{
                Toast.show({
                    icon: 'fail',
                    content: msg,
                })
            }
         } catch (error) {

         }
    }

    getRedEnvelope = async () => {
       document.activeElement.blur();
       const params = this.getUrlQuery()
       try {
        const result = await drawRedPacket(params)
        const { errorCode,value }  = result
        if(errorCode === '0'){
            Toast.show({
                icon: 'success',
                content: '领取成功',
            })
            this.cleanForm()
        }else{
            Toast.show({
                icon: 'fail',
                content: value,
            })
        }
       } catch (error) {

       }
    }

    chageInput = (key,value) => {
        const param = {}
        param[key] = value
        this.setState(param)
    }

    cleanForm(){
       this.setState({phone:'',password:''})
    }

    renderLoginFrom() {
        const { phone,password } = this.state
        return (
            <div className="login">
                <div className="input_view">
                  <Input placeholder="请输入手机号" size="mini" value={ phone } onChange={(e)=>this.chageInput('phone',e.target.value)}/>
                </div>
                <div className="input_view">
                  <Input placeholder="请输入密码" value={ password } onChange={(e)=>this.chageInput('password',e.target.value)} />
                </div>
                <div className="btn" onClick={this.login}>立即领取</div>
                <div className="to_register_view" onClick={this.toRegister}>
                    还没账号？去注册
                </div>
            </div>
        )
    }


    renderRegisterFrom(){
        const { phone,password } = this.state
        return (
            <div className="register">
                <div className="input_view">
                  <Input placeholder="请输入手机号" size="mini" value={phone} onChange={(e)=>this.chageInput('phone',e.target.value)} />
                </div>
                {/* <div className="input_view auto_code_view">
                  <Input placeholder="请输入验证码" onChange={(e)=>this.chageInput('code',e.target.value)} />
                  <div>
                       <PicAuthCode/>
                    </div>
                </div> */}
                <div className="input_view">
                  <Input placeholder="请输入密码" value={password} onChange={(e)=>this.chageInput('password',e.target.value)} />
                </div>
                <div className="btn" onClick={this.register}>注册并立即领取</div>
                <div className="to_register_view" onClick={this.toRegister}>
                    去登录
                </div>
            </div>
        )
    }


    renderNote(){
        return (
            <div className="tip1">
                <p>活动规则</p>
                <p>1、领取红包即刻到账</p>
                <p>2、用户绑定手机后，才能领取红包</p>
                <p>3、红包金额只能在所属店铺下使用</p>
            </div>
        )
    }

    render(){
         const { isRegster } = this.state
        return (
            <div className="red_envelope_body_view">
                {isRegster ? this.renderRegisterFrom() :  this.renderLoginFrom()}
                {this.renderNote()}
            </div>
        )
    }
}


export default Body
