import {
    HashRouter,
    Route,
    Routes,
} from "react-router-dom";
import ShopRegister from "../pages/ShopRegister";
import UserRegister from "../pages/UserRegister";
import RedEnvelope from "../pages/RedEnvelope";
import Home from "../pages/Home/Home";
import DownloadApp from "../pages/SelectApp/DownloadApp";
import SelectApp from "../pages/SelectApp/SelectApp";
import {ConfigProvider} from "antd-mobile";
import zhCN from "antd-mobile/es/locales/zh-CN";
import zhCN1  from "antd/es/locale/zh_CN";
import {ConfigProvider as ConfigProvider1} from "antd";

export default function App() {
    return (
        <ConfigProvider
            locale={zhCN}>
            <ConfigProvider1
                locale={zhCN1}>
                <HashRouter>
                    <Routes>
                        {/* http://localhost:3000/#/ShopRegister */}
                        <Route path="/" element={<Home/>}/>
                        <Route path="/ShopRegister" element={<ShopRegister/>}/>
                        <Route path="/UserRegister" element={<UserRegister/>}/>
                        <Route path="/RedEnvelope" element={<RedEnvelope/>}/>
                        <Route path="/SelectApp" element={<SelectApp/>}/>
                        <Route path="/DownloadApp" element={<DownloadApp/>}/>
                    </Routes>
                </HashRouter>
            </ConfigProvider1>

        </ConfigProvider>

    );
}
