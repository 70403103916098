import React from "react";
import {WithRouter} from '../../conpoment/WithRouter'

import {Button, Input, Modal} from "antd";
import {QRCode} from "antd";
import moment from "moment";
import {Toast} from "antd-mobile";

class SelectApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            app_list: [
                {name: '红牛工具箱', icon: require('../../assets/ic_launcher1.png'), type: 0},
                {name: '与店同行', icon: require('../../assets/ic_launcher2.png'), type: 1},
                {name: '出票员', icon: require('../../assets/ic_launcher3.png'), type: 2},
                {name: '收款助手', icon: require('../../assets/ic_launcher4.png'), type: 3},
            ],
            select_app: {name: '红牛工具箱', icon: require('../../assets/ic_launcher1.png'), type: 0},
            isModalOpen: false,
            codeMinute: 30,
            codeMinuteValue: '',
            codeUrl: '',
        }
    }

    handleSelectBtn = (select_app) => {
        this.setState({select_app})
    }

    handleIsModalOpen = () => {
        this.setState({isModalOpen: !this.state.isModalOpen})
    }

    createAdminQrCode = () => {
        const {codeMinute, select_app} = this.state
        if(!codeMinute || codeMinute < 0) {
            Toast.show({
                icon: 'fail',
                content: '请输入二维码有效时间',
            })
            return
        }
        let time = moment().add(codeMinute, 'minutes').valueOf()
        this.setState({
            codeMinuteValue: moment(time).format('YYYY-MM-DD HH:mm'),
            isModalOpen: true,
            codeUrl: `?type=${select_app.type}&time=${time}`,
        })
    }

    onChangeValue = (e) => {
        // console.log(e.target.value)
        this.setState({codeMinute: e.target.value})
    }

    render() {
        const {app_list, select_app} = this.state
        const {isModalOpen, codeMinute} = this.state
        const {codeMinuteValue, codeUrl} = this.state
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: 130, flexWrap: 'wrap'}}>
                    {app_list.map((item, index) => {
                        const is_select = select_app.name === item.name
                        return(
                            <Button
                                key={index}
                                size={'large'}
                                style={{fontSize: 16, fontWeight: '500', width: 150, display: 'flex', alignItems: 'center', marginTop: 10}}
                                onClick={() => this.handleSelectBtn(item)}
                                type={ is_select ? 'primary' : 'default'}>
                                <img style={{width: 20 , height: 20, borderRadius: 4}} alt={''} src={item.icon}/>
                                <div style={{marginLeft: 5}}>{item.name}</div>
                            </Button>
                        )
                    })}
                </div>
                <center style={{marginTop: 50, fontWeight: '500', color: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Input addonBefore={'有效时间'} addonAfter={'分钟'} size={'large'} style={{fontWeight: '500', color: '#000', fontSize: 18, width: 210}} value={codeMinute} onChange={this.onChangeValue}/>
                    <Button
                        size={'large'}
                        style={{fontSize: 16, fontWeight: '500', marginLeft: 20}}
                        onClick={() => this.createAdminQrCode()}
                        type={'primary'}>
                        {'生成二维码'}
                    </Button>
                </center>
                <Modal open={isModalOpen} onCancel={this.handleIsModalOpen} footer={null}>
                    <center style={{marginTop: 20}}>
                        <div style={{color: '#000', fontSize: 20, fontWeight: 'bold', marginBottom: 20}}>{select_app.name}</div>
                        <QRCode
                            errorLevel="H"
                            value={'https://hntreg.dayunsx.com/#/DownloadApp' + codeUrl}
                            size={230}
                            icon={select_app.icon}
                        />
                        <div style={{color: '#DB4444', fontSize: 16, fontWeight: '500', marginTop: 15}}>二维码过期时间：{codeMinuteValue}</div>
                    </center>
                </Modal>
            </div>
        );
    }
}


export default WithRouter(SelectApp)
