
// 测试环境 'DEVELOP'
// 测试的正式线 'PRODUCT_FAKE'
// 正式环境 'PRODUCT';
let environment = 'PRODUCT';


let devBaseURL = "https://reg.anjiashan.com/"; //开发环境
let proBaseURL = "https://reg.anjiashan.com/"; //打包环境

let userAndroid ='https://sbsm.anjiashan.com/dw/com.hbuser_1.0.1.apk'
let userIos = 'https://apps.apple.com/cn/app/%E6%BD%AE%E6%B1%87-%E8%B4%AD%E7%89%A9/id6444200870'
let storeAndroid = 'https://sbsm.anjiashan.com/dw/com.hbstore_1.0.1.apk'
let stroreIos = 'https://apps.apple.com/cn/app/%E8%B6%85%E5%80%BC%E9%80%89%E7%AB%99/id6639588362'

if(environment == 'DEVELOP'){
     devBaseURL = "https://testreg.anjiashan.com/"; //开发环境
     proBaseURL = "https://testreg.anjiashan.com/"; //打包环境

     userAndroid ='https://sbsm.anjiashan.com/dw/com.hbuser_1.0.1_test.apk'
     userIos = ''
     storeAndroid = 'https://sbsm.anjiashan.com/dw/com.hbstore_1.0.1_test.apk'
     stroreIos = ''
}

export const BASE_URL = process.env.NODE_ENV === 'development' ? devBaseURL: proBaseURL;
export const TIMEOUT = 5000;


//用户down
export const gjzx_app_link = {
    android: userAndroid,
    ios: userIos,
}
//店主down
export const sqgjx_app_link = {
    android: storeAndroid,
    ios: stroreIos,
}

export const ticket_app_link = {
    android: '',
    ios: '',
}

export const assistant_app_link = {
    android: '',
    ios: '',
}
