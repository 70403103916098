import React from "react";
import {WithRouter} from '../../conpoment/WithRouter'

class Home extends React.Component {

    handleBtn = () => {
        let objData = {
            type: 'open_screen',
            path: 'bs_bettingfootball_bettingfootballhome',
            // path: 'bs_bettingbasketball_bettingbasketballhome',
            selectParams: [
                {
                    day: '20231128',
                    teamId: '002',
                    data: ['v3'],
                }
            ]
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(objData));
    }

    render() {
        return (
            <div>
                <center>欢迎使用</center>
                {/*<button onClick={this.handleBtn}>按钮</button>*/}
            </div>
        );
    }
}


export default WithRouter(Home)
