import React from "react";
import { useLocation, useNavigate,useParams } from 'react-router-dom'


export const WithRouter = function(Child) {
    const CallBack = function(props) {
        const location = useLocation()
        const navigate = useNavigate()
        const params = useParams()
        return <Child {...props} location={ location } navigate={ navigate } params={ params } />
    }

    return props => {
        return <CallBack {...props}/>
    }
}


